import React, { useEffect } from "react"
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import { ROUTES } from 'controller/routes/routes';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux"

import DifficultyComponent from "render/views/components/DifficultyComponent";
import BodyPartsComponent from "render/views/components/BodyPartsComponent";
import LessonComponent from 'render/views/pages/Lessons/LessonComponent';
import { fetchLesson, fetchLessons } from "controller/redux/HomeSlice";

import { getDataBySlug, getRelated, unslug } from "scripts/utils/Utils";
import ErrorPage from "../ErrorPage";

import { HOME_MIN_WIDTH_SLIDER } from 'scripts/Constants';
import { useWindowDimensions } from 'controller/hooks/hooks';
import LessonsSlider from '../Lessons/LessonsSlider';

import _ from 'lodash';
import VideoPlayer from "render/views/components/VideoPlayer";
import { useDelayedLoading } from "controller/hooks/hooks";
import { LOADING_TIME } from "scripts/Constants";
import LoadingPageComponent from "render/views/components/LoadingPageComponent";

const LessonDetails = () => {
  const dimensions = useWindowDimensions();

  const { slug } = useParams();

  const { lesson, lessons } = useSelector(
    (state) => state.home
  );

  const dispatch = useDispatch();

  const relatedLessons = getRelated(lessons, lesson, 4); // Get four related lessons
  const groupedByBox = _.groupBy(lesson.schedules, 'box');

  const groupedSchedules = {};
  for (const box in groupedByBox) {
    // First group by weekDay
    const grouped = _.groupBy(groupedByBox[box], 'weekDay');

    // Sort the keys numerically
    groupedSchedules[box] = Object.keys(grouped)
      .sort((a, b) => {
        // Remove the 'a' and convert to number for proper numerical sorting
        const numA = parseInt(a.replace('a', ''));
        const numB = parseInt(b.replace('a', ''));
        return numA - numB;
      })
      .reduce((obj, key) => {
        obj[key] = grouped[key];
        return obj;
      }, {});
  }
  const error = useSelector((state) => state.home.error);

  useEffect(() => {
    setTimeout(() => { // SIMULAR TEMPO DO LOADING, TEM QUE SE TIRAR PARA FICAR COM DADOS

      dispatch(fetchLesson(slug));

      if (Object.keys(lessons).length === 0) {
        dispatch(fetchLessons());
      }
    }, LOADING_TIME);
  }, [dispatch, slug]);

  return (
    <div id="page-container" page="lessons-list">
      <Helmet>
        <title>Sthenos | {String(lesson.title)}</title>
        <meta name="description" content="Vem conhecer as nossas aulas!" />
        <meta name="keywords" content="carnaxide,box,calistenia,portugal" />
      </Helmet>
      {
        ((lesson && lesson.slug === slug) && Object.keys(lesson).length !== 0 && lessons && lessons.length !== 0) ? (
          <>
            <section id="lesson-detail">
              <div className="container page-header-no-image">

                <div className="flex-column hide flex-on-breakpoint-m">
                  <Link to={ROUTES.LESSONS} className="btn inline-link has-icon text-gray"><i className="icon-ml icon-chevron-left"></i><span>Voltar a todas as aulas</span></Link>
                  <hr className="hr-s" />
                  <h1 className="section-title text-white-offset">
                    <span className="text-accent text-outline">Aula</span>
                    <span>{lesson.title}</span>
                  </h1>
                </div>

                <div id="lesson-detail--main">
                  <div id="lesson-info">
                    <div className="hide-on-breakpoint-m">
                      <Link to={ROUTES.LESSONS} className="btn inline-link has-icon text-gray"><i className="icon-ml icon-chevron-left"></i><span>Voltar a todas as aulas</span></Link>
                      <hr className="hr-s" />
                      <h1 className="section-title text-white-offset">
                        <span className="text-accent text-outline">Aula</span>
                        <span>{lesson.title}</span>
                      </h1>

                      <hr className="hr-xl" />
                    </div>


                    <div className="difficulty-container">
                      <DifficultyComponent label={"Força / Resistência"} rating={lesson.strength} />
                      <DifficultyComponent label={"Queima Calórica"} rating={lesson.burn} />
                      <DifficultyComponent label={"Mobilidade / Flexibilidade"} rating={lesson.flexibility} />
                      <DifficultyComponent label={"Skill Calistenia"} rating={lesson.skill} />
                    </div>

                    <div className="flex-row align-end gap-l">
                      <BodyPartsComponent bodyParts={lesson.bodyParts} />
                    </div>

                    <hr className="hr-xl" />

                    <div className="flex-column gap-xs">
                      {Object.keys(groupedSchedules).map((box) => (
                        <div key={box}>
                          {/* <h2 style={{color:"white"}}>{unslug(box)}</h2> */}
                          <div className="flex-column gap-xxs">
                            <p className="text-accent">Horário {unslug(box)}:</p>
                            <div className="detail-schedule-container">
                              {Object.keys(groupedSchedules[box]).map((weekDay) => (
                                <div className="schedule-group" key={weekDay}>
                                  <p className="text-white-offset">{weekDay}</p>
                                  <div className="schedule-item">
                                    {groupedSchedules[box][weekDay].map((schedule) => (
                                      <p className="text-gray" key={schedule.id}>
                                        {schedule.startTime} - {schedule.endTime}
                                      </p>
                                    ))}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <VideoPlayer src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/lessons/${lesson.slug}/sthenos-fit-lesson-${lesson.slug}.mp4`} />
                </div>
              </div>

              <div className="bg-image-positioner">
                <img src={`${process.env.REACT_APP_MEDIA_BASE_PATH_STATIC}/auxiliary/sthenos-fit-group-lesson.jpg`} alt="" />
              </div>
            </section>

            <hr className="hr-xl" />

            <section id="lesson-description">
              <div className="container-sm">
                <p className="text-description-xxl text-accent">{lesson.description_pt}</p>
              </div>
            </section>

            <hr className="hr-xl" />

            <div className="flex-column align-center gap-m">
              <h3 className="section-title text-white-offset">Vamos ao push?</h3>
              <Link to={ROUTES.SUBSCRIBE} className="btn btn-primary-ghost"><span>Marcar Aula</span></Link>
            </div>

            <hr className="hr-xl" />
            <hr className="hr-xxl vertical-line" />
            <hr className="hr-l" />

            <section className="container-m" id="related-lessons">
              <div className="flex-row justify-center align-center text-center gap-s flex-column-on-breakpoint-m" id="related-lessons--title">
                <h2 className="section-title">Mais aulas</h2>
                <Link to={ROUTES.LESSONS} className="btn inline-link text-white">Ver lista de todas as aulas</Link>
              </div>
              <hr className="hr-0 hr-m-on-breakpoint-m" />
              {dimensions.width < HOME_MIN_WIDTH_SLIDER ? <LessonsSlider lessons={relatedLessons} /> : <LessonComponent lessons={relatedLessons} />}
            </section>

            <hr className="hr-xl" />
          </>
        ) : (error && error.includes("not found")) ? (
          <ErrorPage />
        ) : (
          <LoadingPageComponent />
        )}

    </div>

  );

};

export default LessonDetails;