import React, { useRef } from "react";
import { Link } from "react-router-dom";

import { ROUTES } from "controller/routes/routes";
import { useTranslation } from 'react-i18next';

import * as api from 'api/SthenosAPI'
import { toast } from "react-toastify";

import { useLocation } from "react-router-dom";

function FooterComponent() {
  var currentYear = new Date().getFullYear();
  const { t } = useTranslation();

  const emailNewsletter = useRef();
  const toastId = useRef(null);

  const location = useLocation();

  const handleNewsletterSubscribe = async (event) => {
    event.preventDefault();

    toastId.current = toast("A registar email...", { type: toast.TYPE.INFO, autoClose: false });
    const inputValue = emailNewsletter.current.value;
    const subscribe_email = {
      email: inputValue,
    };
    await api.postData(api.API_ENDPOINTS.SUBSCRIBE_EMAIL, subscribe_email, null,
      (response) => {
        toast.update(toastId.current, {
          render: "Email registado com sucesso!",
          type: toast.TYPE.SUCCESS,
          autoClose: 5000
        })
        emailNewsletter.current.value = '';
      },
      (error) => {
        toast.update(toastId.current, {
          render: "Erro ao registar email",
          type: toast.TYPE.ERROR,
          autoClose: 5000
        })
        emailNewsletter.current.value = '';
      }, 1000
    );
  };

  return (
    <footer id="main-footer" location={location.pathname}>
      <div id="main-footer--pre">
        <div className="container">
          <h3 className="uppercase">Pronto para treinar?</h3>
          <Link to={ROUTES.SUBSCRIBE} className="btn btn-primary-ghost">
            <span>Inscreve-te já</span>
          </Link>
        </div>
      </div>
      <div id="main-footer--content">
        <div className="container-m" >
          <img className="logo" src={`${process.env.REACT_APP_MEDIA_BASE_PATH_STATIC}/brand/sthenos-logo.svg`} alt='Sthenos Fit Logo' />
          <div className="main-footer--info">
            <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Calistenia+Portugal+-+STHENOS.FIT/@38.7191615,-9.2305172,17z/data=!3m1!4b1!4m6!3m5!1s0xd1ecd6d48701411:0x5dee308f71347ac4!8m2!3d38.7191615!4d-9.2283285!16s%2Fg%2F11qntpx69p">
              <p><b>Carnaxide:</b></p>
              <span>Av. Comendador<br />Nunes Corrêa, 72A Carnaxide</span>
              <br />
              <span>2150-000 Carnaxide</span>
            </a>
            <br></br>
            <a target="_blank" rel="noreferrer" href="https://www.google.pt/maps/place/Sthenos+Benfica+-+Calistenia+Portugal/@38.7471502,-9.2002568,17z/data=!3m1!4b1!4m6!3m5!1s0xd1ecdfe43ee6f9d:0x16fe05ed83964049!8m2!3d38.747146!4d-9.1976819!16s%2Fg%2F11vcvgp60f?hl=pt-PT&entry=ttu">
              <p><b>Benfica:</b></p>
              <span>Av. Gomes Pereira, 41A Benfica</span>
              <br />
              <span>1500-328 Lisboa</span>
            </a>
          </div>


          <div className="main-footer--info">
            <a target="_blank" rel="noreferrer" href="tel:+351927008654">(+351) 927 008 654</a>
            <a target="_blank" rel="noreferrer" href="mailto:geral@calisteniaportugal.fit">geral@calisteniaportugal.fit</a>
          </div>


          <div className="main-footer--icons">
            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/sthenos.fit/">
              <img alt="" src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/icons/instagram-icon.svg`} />
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/calisteniaportugal.fit/">
              <img alt="" src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/icons/facebook-icon.svg`} />
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UC9ogRUOIPS-vQSwAwswbNxw">
              <img alt="" src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/icons/youtube-icon.svg`} />
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.tiktok.com/@calisteniaportugal.fit?_t=ZN-8u10rzWK7zq&_r=1">
              <img alt="" src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/icons/tiktok-icon.svg`} />
            </a>
          </div>
          <form id="newsletter-form" onSubmit={handleNewsletterSubscribe}>
            <input className="btn-l" ref={emailNewsletter} placeholder={t('footer_label_11')} />
            <button type="submit" className="btn-l btn-primary-fill"><span>{t('footer_label_2')}</span><i className="icon-send"></i></button>
          </form>
          <nav id="main-footer--nav">
            <div>
              <Link to={`${ROUTES.BOX}/carnaxide`} >Box</Link>
              <Link to={ROUTES.LESSONS} >{t('footer_label_3')}</Link>
              <Link to={ROUTES.EXERCISES} >{t('footer_label_4')}</Link>
              <Link to={ROUTES.COURSES} >{t('footer_label_5')}</Link>
              {/* <Link to={ROUTES.STORE} >{t('footer_label_6')}</Link> */}
              <Link to={ROUTES.SUBSCRIBE} >{t('footer_label_7')}</Link>
              {/* <Link to={ROUTES.FAQ} >FAQs</Link> */}
            </div>
            <div>
              <span>Copyright © {currentYear} {t('footer_label_8')}</span>
              <Link to={ROUTES.TERMS_CONDITIONS} ><span>{t('footer_label_9')}</span></Link>
            </div>
          </nav>
          <div id="main-footer--official-representatives">
            <a target="_blank" rel="noreferrer" href="https://calisteniaportugal.fit">
              <img alt="" src={`${process.env.REACT_APP_MEDIA_BASE_PATH_STATIC}/brand/sthenos-logo.svg`} />
            </a>
            <span>Powered by Calistenia Portugal</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;