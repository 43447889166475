import { React, useState, useEffect } from 'react';

import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

import LessonComponent from 'render/views/pages/Lessons/LessonComponent';
import LoadingPageComponent from 'render/views/components/LoadingPageComponent';

import { ROUTES } from 'controller/routes/routes';
import { useSelector, useDispatch } from 'react-redux';

import CourseComponent from 'render/views/pages/Courses/CourseComponent';
import { getRandomData } from 'scripts/utils/Utils';
import ExerciseComponent from 'render/views/pages/Exercises/ExerciseComponent';
import { fetchLessons, fetchCourses, fetchExercises, fetchTrainingPlans, fetchEbooks } from 'controller/redux/HomeSlice';
import TrainingPlansComponent from 'render/views/pages/Training Plans/TrainingPlansComponent';
import { HOME_MIN_WIDTH_SLIDER, MAX_ITEMS_HOME } from 'scripts/Constants';
import { useWindowDimensions } from 'controller/hooks/hooks';
import LessonsSlider from '../Lessons/LessonsSlider';
import ExercisesSlider from '../Exercises/ExercisesSlider';
import EbookComponent from '../Ebooks/EbookComponent';
import EbooksSlider from '../Ebooks/EbooksSlider';

function TrainingPage() {
  const { lessons, courses, exercises, training_plans, ebooks, isDataFetched } = useSelector(
    (state) => state.home
  );
  const dimensions = useWindowDimensions();

  const dispatch = useDispatch();
  const featuredLessons = lessons.filter(lesson => lesson.featured).slice(0, MAX_ITEMS_HOME); // Get only the featured lessons
  const randomFiveExercises = getRandomData(exercises, 5); // Get five ramdom featured exercises
  const featuredCourses = getRandomData(courses.filter(course => course.featured), 2); // Get two ramdom featured courses
  const [isLoading, setIsLoading] = useState(true);

  console.log(ebooks)

  useEffect(() => {
    if (!isDataFetched) {
      Promise.all([
        dispatch(fetchLessons()),
        dispatch(fetchCourses()),
        dispatch(fetchExercises()),
        dispatch(fetchTrainingPlans()),
        dispatch(fetchEbooks())
      ]).then(() => {
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [dispatch, isDataFetched]);

  if (isLoading) {
    return (
      <LoadingPageComponent />
    )
  }

  return (
    <div id="page-container" page="training-list">
      <Helmet>
        <title>Sthenos | Treinar </title>
        <meta name="description" content="Vem treinar com a Calistenia Portugal!" />
        <meta name="keywords" content="carnaxide,box,calistenia,portugal" />
      </Helmet>

      <div className="page-header-image">
        <img alt="" src={`${process.env.REACT_APP_MEDIA_BASE_PATH_STATIC}/auxiliary/sthenos-fit-box-main.jpg`} />
      </div>

      <section className="container p-0">
        <h1 className="page-title">Treinar</h1>
        <hr className="hr-m" />
        <section className="container-m p-0">
          <h2 className="uppercase text-accent text-outline-thicker text-center">Os treinos com o peso do corpo são a nossa praia!</h2>
        </section>
        <hr className="hr-l" />
        <section className="container-xs p-0">
          <p className="text-l text-white-offset text-center">
            Além da <span className="underline-accent">CALISTENIA</span>, temos <span className="underline-accent">FIT MOMS</span>, <span className="underline-accent">MOBILIDADE</span> e <span className="underline-accent">BURN HIIT</span>! Com mais de 10 aulas diárias sempre com a orientação de um coach, vais poder aprender a dominar o teu corpo nas diferentes vertentes do treino calisténico! Não existe um nível certo, do principiante ao mais avançado, todas as aulas estão adaptadas para te desafiar e evoluires.
          </p>
        </section>
        <hr className="hr-xxl hr-xl-on-breakpoint-xs" />

        <section className="container-m" id="section-featured-lessons">
          <div className="section-header flex-row justify-between align-center flex-column-on-breakpoint-xs gap-s-on-breakpoint-xs">
            <h2 className="section-title">Aulas</h2>
            <Link to={ROUTES.LESSONS} className="btn inline-link text-white">Ver todas as Aulas</Link>
          </div>
          <hr className="hr-0 hr-m-on-breakpoint-xs" />
          {dimensions.width < HOME_MIN_WIDTH_SLIDER ? <LessonsSlider lessons={featuredLessons} /> : <LessonComponent lessons={featuredLessons} />}
        </section>

        <hr className="hr-xxxl hr-xxl-on-breakpoint-s hr-xl-on-breakpoint-xs" />

        <section className="container-m" id="section-featured-exercises">
          <div className="section-header flex-row justify-between align-center flex-column-on-breakpoint-xs gap-s-on-breakpoint-xs">
            <h2 className="section-title">Exercícios</h2>
            <Link to={ROUTES.EXERCISES} className="btn inline-link text-white">Ver todos os Exercícios</Link>
          </div>
          <hr className="hr-0 hr-m-on-breakpoint-xs" />
          {dimensions.width < HOME_MIN_WIDTH_SLIDER ? <ExercisesSlider exercises={randomFiveExercises} /> : <ExerciseComponent exercises={randomFiveExercises} />}
        </section>

        <hr className="hr-xxxl hr-xxl-on-breakpoint-s hr-xl-on-breakpoint-xs" />

        <section className="container-m" id="section-featured-courses">
          <div className="flex-row justify-between align-center flex-column-on-breakpoint-xs gap-s-on-breakpoint-xs">
            <h2 className="section-title">Cursos</h2>
            <Link to={ROUTES.COURSES} className="btn inline-link text-white">Ver todos os Cursos</Link>
          </div>
          <hr className="hr-0 hr-m-on-breakpoint-xs" />
          <CourseComponent courses={featuredCourses} />
        </section>

        <hr className="hr-xxxl hr-xxl-on-breakpoint-s hr-xl-on-breakpoint-xs" />

        <section className="container-m" id="section-featured-ebooks">
          <div className="section-header flex-row justify-between align-center flex-column-on-breakpoint-xs gap-s-on-breakpoint-xs">
            <h2 className="section-title">Ebooks</h2>
            <Link to={ROUTES.EBOOKS} className="btn inline-link text-white">Ver todas os Ebooks</Link>
          </div>
          <hr className="hr-0 hr-m-on-breakpoint-xs" />
          <EbookComponent ebooks={ebooks} />
        </section>


        {/* <hr className="hr-xxxl" />

        <section>
          <div className="flex-row justify-between align-center">
            <h2 className="section-title">Planos de Treino</h2>
            <Link to={ROUTES.TRAINING_PLANS} className="btn inline-link text-white">Ver todos os Planos de Treino</Link>
          </div>
          <div className="flex-row justify-center gap-l">
            <TrainingPlansComponent training_plans={training_plans} />
          </div>
        </section> */}

        <hr className="hr-xxl hr-0-on-breakpoint-xs" />
        <hr className="hr-xxl hr-m-on-breakpoint-m" />
      </section>
    </div>
  );
}

export default TrainingPage